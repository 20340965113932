import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Playlist = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { navigate } = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      {isAuthenticated && (
        <Box
          sx={{
            marginTop: 4, // This will still apply
            width: isMobile ? "70%" : "50%", // Adjust this value as needed
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            component="body2"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            The playlist below contains songs that we enjoy that you will
            probably hear at the wedding. We will be updating this playlist
            regularly over the next few months so check back for more songs!
          </Typography>
          <br />
          <iframe
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/1jrVt0QhXqC0jEVCYgpOgm?utm_source=generator&theme=0"
            width="100%"
            height="352"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Box>
      )}
    </div>
  );
};

export default Playlist;
