import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import coverPhoto from "../../assets/images/L&M-6.jpg";

import "react-vertical-timeline-component/style.min.css";
import { AuthContext } from "../../contexts/AuthContext.js"; // Adjust the path as needed
import EventIcon from "@mui/icons-material/Event"; // Import the EventIcon component from MUI
import { format, parseISO, set } from "date-fns";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography, Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ScheduleComponent = () => {
  const [schedule, setSchedule] = useState([]);
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "MMMM do, h:mm aa"); // e.g., "June 23rd, 2020, 7:40 PM"
  };

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      setLoading(true);

      axios
        .get("/api/events/schedule/", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        })
        .then((response) => {
          setSchedule(response.data);
          setLoading(false); // Move this inside the .then block
        })
        .catch((error) => {
          console.error("There was an error fetching the schedule", error);
          setLoading(false); // Also set loading to false in case of an error
        });
    } else {
      // Redirect to login or show a message
      console.log("User not authenticated");
    }
  }, [isAuthenticated]); // Add isAuthenticated as a dependency

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          maxWidth: 600,
          mx: "auto",
          marginTop: "5px",
          display: loading ? "flex" : "block",
          justifyContent: loading ? "center" : "initial",
          alignItems: loading ? "center" : "initial",
          minHeight: loading ? "100vh" : "auto",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Timeline position="alternate" sx={{ marginTop: isMobile ? 2 : 8 }}>
            {Array.isArray(schedule) &&
              schedule.map((event) => (
                <TimelineItem key={event.id}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                    fontSize={isMobile ? "0.8rem" : "1rem"}
                  >
                    {format(new Date(event.start_time), "MMM d, h:mm a")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary">
                      <EventIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography
                      variant="body2"
                      component="span"
                      fontWeight={"medium"}
                      fontSize={isMobile ? "0.8rem" : "1rem"}
                    >
                      {event.title}
                    </Typography>
                    <Typography fontSize={isMobile ? "0.8rem" : "1rem"}>
                      {event.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        )}
      </Box>
    </div>
  );
};

export default ScheduleComponent;
