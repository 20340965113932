import React, { useState, useContext, useEffect } from "react";
import Navbar from "../components/common/navbar.js";
import {
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Home from "../components/home/home.js";
import Schedule from "../components/schedule/schedule.js";
import Accommodation from "../components/accommodation/accommodation.js";
import FAQ from "../components/faq/faq.js";
import Gallery from "../components/gallery/gallery.js";
import { AuthContext } from "../contexts/AuthContext.js";
import { useNavigate } from "react-router-dom";
import treesImage from "../assets/images/trees2.png";
import background from "../assets/images/background.jpg";
import Playlist from "../components/playlist/playlist.js";
import Footer from "../components/common/footer.js";
import RSVP from "../components/rsvp/rsvp.js";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomePage = () => {
  const { user, isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState("home");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const weddingDate = new Date("December 14, 2024");
  const currentDate = new Date();
  const daysToGo = Math.ceil(
    (weddingDate - currentDate) / (1000 * 60 * 60 * 24)
  );

  const selectSection = (section) => {
    setCurrentSection(section);
  };

  const renderSection = () => {
    switch (currentSection) {
      case "home":
        return <Home />;
      case "schedule":
        return <Schedule />;
      case "photos":
        return <Gallery />;
      case "accommodation":
        return <Accommodation />;
      case "faq":
        return <FAQ />;
      case "playlist":
        return <Playlist />;
      case "rsvp":
        return <RSVP />;
      default:
        return <Home />;
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    "Home",
    "Schedule",
    "Photos",
    "Accommodation",
    "RSVP",
    "FAQ",
    "Playlist",
    "Logout",
  ];

  const underlineStyle = {
    textDecoration: "underline",
    textDecorationColor: "#000",
    textDecorationThickness: "2px",
    textUnderlineOffset: "6px",
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{
        width: isMobile ? "100vw" : 250,
        height: isMobile ? "100vh" : "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: isMobile ? "center" : "flex-start",
        justifyContent: isMobile ? "center" : "flex-start",
        backgroundColor: isMobile ? "white" : "inherit",
      }}
    >
      <List>
        {menuItems.map((text) => (
          <ListItem
            button
            key={text}
            onClick={() =>
              text === "Logout"
                ? logout()
                : selectSection(text.replace(/\s+/g, "").toLowerCase())
            }
          >
            <ListItemText
              primary={text}
              primaryTypographyProps={{
                style:
                  text.replace(/\s+/g, "").toLowerCase() === currentSection
                    ? underlineStyle
                    : {},
              }}
              style={{ textAlign: isMobile ? "center" : "left" }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          margin: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          opacity: 0.7,
        }}
      />
      <div style={{ position: "relative", zIndex: 1 }}>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            style={{
              color: "white",
              position: "fixed",
              right: 15,
              top: 15,
              zIndex: 2,
              borderRadius: "75%",
              backgroundColor: "grey",
              padding: "10px",
            }}
          >
            <MenuIcon style={{ fontSize: "2rem" }} />
            {/* <Typography variant="caption" style={{ marginLeft: "8px" }}>
              Menu
            </Typography> */}
          </IconButton>
        )}
        <Box
          sx={{
            marginTop: 4,
            width: isMobile ? "95%" : "90%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
        >
          <img
            src={treesImage}
            alt="landingImage"
            style={{
              width: isMobile ? "110%" : "80%",
              justifyContent: "center",
            }}
          />
        </Box>
        <Typography
          component="h1"
          variant={isMobile ? "h6" : "h1"}
          textAlign="center"
          fontWeight="light"
          fontSize={isMobile ? "1.8rem" : "4rem"}
        >
          LLEWELLYN & MEGAN
        </Typography>
        <Typography
          component="h6"
          variant={isMobile ? "h6" : "h6"}
          textAlign="center"
          sx={{
            marginTop: 3,
            marginBottom: 2,
            fontSize: isMobile ? "0.85rem" : "1rem",
          }}
        >
          December 14, 2024 | Cherry Glamping, Elgin Valley
          <br />
          {daysToGo > 0 ? `${daysToGo} days to go!` : ""}
        </Typography>
        <Typography
          component="h4"
          variant={isMobile ? "body2h6" : "h6"}
          textAlign="center"
          sx={{ marginTop: 3, marginBottom: 2 }}
        ></Typography>
        {!isMobile && (
          <Navbar
            userName={user ? `${user.first_name}` : "Guest"}
            onSelect={selectSection}
            onLogout={() => {
              logout();
              navigate("/login", { replace: true });
            }}
            currentSection={currentSection}
            isMobile={isMobile}
            toggleDrawer={toggleDrawer}
            drawerOpen={drawerOpen}
          />
        )}
        {renderSection()}
        <Footer />
      </div>
      {isMobile && (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      )}
    </div>
  );
};

export default HomePage;
