// LoginPage.js or wherever your login form is

import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext"; // Adjust the import path as needed
import { Button, Container, Box, Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const authContext = useContext(AuthContext);
  let navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await authContext.login(username, password);
      navigate("/home");
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.detail);
      } else {
        setErrorMessage("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          textAlign={"center"}
          sx={{ marginBottom: "10px" }}
        >
          Login
        </Typography>
        <Typography component="body2" variant="body2" marginTop={"5px"}>
          Please login with the username and password emailed to you!
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {errorMessage && (
            <Typography component="p" variant="body2" sx={{ color: "red" }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
