import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/images/std_2.jpeg";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomePage = () => {
  let navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      style={{
        backgroundImage: `url(${backgroundImage})`, // backgroundImage is the imported image variable
        backgroundSize: isMobile ? "80%" : "35%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: isMobile ? "70vh" : "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Box
        style={{
          textAlign: "center",
          backgroundColor: "rgba(255, 255, 255, 1)", // Optional: for better readability
          padding: "5px",
          width: "100%",
        }}
      >
        <Button
          onClick={handleLoginRedirect}
          variant="outlined"
          color="primary"
          style={{ marginTop: isMobile ? 4 : 8 }}
          size="small"
        >
          Get Started
        </Button>
      </Box>
    </Box>
  );
};

export default HomePage;
