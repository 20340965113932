import axios from "axios";

const API_URL =
  "https://llewandmeg-9b046b0fdf78.herokuapp.com/api/" ||
  "http://localhost:8000/api/";

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "token/", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.access) {
          // Store only the token and minimal user info if needed
          const token = response.data.access; // Assuming the token is in the 'access' field
          localStorage.setItem("token", token);
          localStorage.setItem(
            "user",
            JSON.stringify({
              username: response.data.username,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
            })
          );
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  }

  getCurrentUser() {
    try {
      const user = localStorage.getItem("user");
      return user ? JSON.parse(user) : null;
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      return null;
    }
  }
}

export default new AuthService();
