import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider, Button } from "@mui/material";

// Importing Roboto fonts
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// import rubik fonts
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/rubik/700.css";

// import solway fonts
import "@fontsource/solway/300.css";
import "@fontsource/solway/400.css";
import "@fontsource/solway/500.css";
import "@fontsource/solway/700.css";
import "@fontsource/solway/800.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#16392c", // Set your desired primary color
      light: "#E5C7ACff", // Customize the light variant
      dark: "#62947c", // Customize the dark variant
      contrastText: "#fff", // Set text color for contrast
    },
    secondary: {
      main: "#f2a341", // Set your desired secondary color
      light: "#A89C77ff", // Customize the light variant
      dark: "#2C3408ff", // Customize the dark variant
      contrastText: "#fff", // Set text color for contrast
    },
    // Add more customizations for other palette colors as needed
    // You can customize text, background, and other color properties
    text: {
      primary: "#16392c", // Set the primary text color
      secondary: "#777", // Set the secondary text color
    },
    background: {
      default: "#f5f5f5", // Set the default background color
      paper: "#fff", // Set the background color for paper surfaces
    },
  },
  typography: {
    fontFamily: "Rubik", // Set the default font family
    fontSize: 14, // Set the default font size
    fontWeightLight: 300, // Set the font weight for light typography
    fontWeightRegular: 300, // Set the font weight for regular typography
    fontWeightMedium: 500, // Set the font weight for medium typography
    fontWeightBold: 700, // Set the font weight for bold typography
    h1: {
      fontFamily: "Solway",
      fontSize: "3.8rem",
      fontWeight: 500,
      color: "#16392c",
    },
    h2: {
      fontSize: "3.75rem", // Set the h2 font size
      fontWeight: 500, // Set the h2 font weight
    },
    h3: {
      fontSize: "3rem", // Set the h3 font size
      fontWeight: 500, // Set the h3 font weight
      color: "#16392c",
    },
    h4: {
      fontSize: "1.4rem", // Set the h4 font size
      fontWeight: 500, // Set the h4 font weight
      color: "#16392c",
    },
    h5: {
      fontSize: "1.5rem", // Set the h5 font size
      fontWeight: 500, // Set the h5 font weight
      color: "#16392c",
    },
    h6: {
      fontSize: "1.25rem", // Set the h6 font size
      fontWeight: 300, // Set the h6 font weight
      color: "#16392c",
    },
    // create special header for the couple's name using solway font
    h7: {
      fontFamily: "Solway",
      fontSize: "3.8rem",
      fontWeight: 500,
      color: "#16392c",
    },
    body1: {
      fontSize: "1rem", // Set the body1 font size
      fontWeight: 100, // Set the body1 font weight
      color: "#16392c",
    },
    body2: {
      fontSize: "1rem", // Set the body2 font size
      fontWeight: 100, // Set the body2 font weight
      color: "#16392c",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
