import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Replace 'download.jpeg' with your image filename

const NotFoundPage = () => {
  let navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h3" gutterBottom>
        404 - Page Not Found
      </Typography>
      <Typography variant="h6" gutterBottom>
        The page you are looking for does not exist.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleLoginRedirect}
        sx={{ marginTop: 2 }}
      >
        Go to Login
      </Button>
    </Box>
  );
};

export default NotFoundPage;
