import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./gallery.css"; // Make sure to create a corresponding CSS file

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagesByYear, setImagesByYear] = useState({});
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     navigate("/login");
  //   }
  // }, [isAuthenticated, navigate]);

  // useEffect(() => {
  //   async function fetchImages() {
  //     try {
  //       const response = await axios.get("/api/gallery/images/2016/2024/");
  //       setImagesByYear(response.data);
  //     } catch (error) {
  //       console.error("Failed to fetch images:", error);
  //     }
  //   }

  //   fetchImages();
  // }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      //console.log(token); // Retrieve the token

      axios
        .get("/api/gallery/images/2016/2024/", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        })
        .then((response) => {
          setImagesByYear(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the images", error);
        });
    } else {
      // Redirect to login or show a message
      console.log("User not authenticated");
    }
  }, [isAuthenticated]);

  return (
    <Box
      id="gallery"
      sx={{
        marginTop: 4,
        width: isMobile ? "80%" : "60%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {Object.keys(imagesByYear).map((year) => (
        <Box key={year} sx={{ marginBottom: 4 }}>
          <Typography variant="h4" gutterBottom>
            {year}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gridGap: "16px",
            }}
          >
            {Array.isArray(imagesByYear[year]) &&
              imagesByYear[year].map((image, index) => (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    paddingBottom: "75%", // 4:3 aspect ratio
                    overflow: "hidden",
                    borderRadius: "5px",
                    backgroundImage: `url(${image.src})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedImage(image.src)}
                />
              ))}
          </Box>
        </Box>
      ))}
      {selectedImage && (
        <div className="modal" onClick={() => setSelectedImage(null)}>
          <span className="close">&times;</span>
          <img className="modal-content" src={selectedImage} alt="Enlarged" />
        </div>
      )}
    </Box>
  );
};

export default Gallery;
