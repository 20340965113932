import { useEffect } from "react";

const RedirectToDjangoAdmin = () => {
  useEffect(() => {
    // Redirect to the Django admin page
    window.location.href = "/admin/";
  }, []);

  return null; // Render nothing while the redirect takes effect
};

export default RedirectToDjangoAdmin;
