import React, { createContext, useState, useEffect } from "react";
import AuthService from "../services/AuthService"; // Adjust the import path as needed

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userDetails = AuthService.getCurrentUser();
    if (userDetails) {
      setUser(userDetails); // userDetails is now the object stored in localStorage
      setIsAuthenticated(true);
    }
  }, []);

  const login = (username, password) => {
    return AuthService.login(username, password).then((data) => {
      // Assuming the login method now stores user details as described earlier
      const userDetails = {
        username: data.username,
        first_name: data.first_name,
        last_name: data.last_name,
      };
      setUser(userDetails); // Use the structured user details
      setIsAuthenticated(true);
      return data;
    });
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
