import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { AuthProvider } from "./contexts/AuthContext";
import NotFoundPage from "./pages/NotFoundPage";
import RedirectToDjangoAdmin from "./components/common/RedirectToDjango"; // The redirect component

// Other imports...

// /* CSS HEX */
// white: #FFFFFFff;
// drab-dark-brown: #463F04ff;
// butterscotch: #CC8C36ff;
// white-2: #FDFDFCff;
// drab-dark-brown-2: #2C3408ff;
// golden-brown: #885A17ff;
// desert-sand: #E5C7ACff;
// black: #000000ff;
// dark-moss-green: #405F20ff;
// ecru: #A89C77ff;

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<HomePage />} />
          {/* Other routes */}
          <Route path="/admin" element={<RedirectToDjangoAdmin />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
