import React from "react";
import { Box, Typography } from "@mui/material";
import { light } from "@mui/material/styles/createPalette";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        textAlign: "center",
        marginTop: "40px",
        paddingBottom: "20px",
      }}
    >
      <Typography variant="h2" color="primary" gutterBottom fontWeight="light">
        M&L
      </Typography>
      <Box
        sx={{
          width: "12.50%",
          height: "1px",
          bgcolor: "primary.main",
          margin: "auto",
          marginBottom: "10px",
        }}
      />
      <Typography variant="subtitle1" color="primary">
        14.12.2024
      </Typography>
    </Box>
  );
};

export default Footer;
